<template>
  <div class="AC_manage111">
    <div class="index_main111">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条订单吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <p class="search">
        <el-select
          placeholder="项目状态"
          v-model="searchForm.schedule"
          v-if="permissoin.includes('query')"
          clearable
          size="mini"
        >
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- <el-select
          placeholder="区域"
          v-model="searchForm.schedule3"
          v-if="permissoin.includes('query')"
          clearable
          size="mini"
        >
          <el-option
            v-for="item in optionarea"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <el-input
          v-if="permissoin.includes('query')"
          type="text"
          size="mini"
          clearable
          v-model="searchForm.areaName"
          placeholder=" 请输入区域关键字"
        ></el-input>
        <el-select
          placeholder="运维期限"
          v-model="searchForm.overTime"
          v-if="permissoin.includes('query')"
          clearable
          size="mini"
        >
          <el-option
            v-for="item in optionoperate"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- <el-select
          placeholder="应收账款状态"
          v-model="searchForm.schedule1"
          v-if="permissoin.includes('query')"
          clearable
          size="mini"
        >
          <el-option
            v-for="item in optionpay"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <!-- <el-date-picker
         size="mini"
      v-model="searchForm.schedule6"
      type="daterange"
      range-separator="至"
      start-placeholder="收款日期"
      end-placeholder="结束日期">
    </el-date-picker> -->
        <el-select
          multiple
          collapse-tags
          placeholder="业务员/代理商"
          v-model="searchForm.userId"
          v-if="permissoin.includes('query')"
          clearable
          size="mini"
        >
          <el-option
            v-for="item in option30"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          >
          </el-option>
        </el-select>
        <el-input
          v-if="permissoin.includes('query')"
          type="text"
          size="mini"
          clearable
          v-model="searchForm.name"
          placeholder=" 请输入项目关键字"
        ></el-input>
        <button
          class="btn2"
          v-if="permissoin.includes('query')"
          @click="searchForm = {}"
        >
          <i class="el-icon-refresh"></i>
          重置
        </button>
        <button
          class="btn1"
          v-if="permissoin.includes('query')"
          @click="
            currentPage2 = 1;
            getData();
          "
        >
          <i class="el-icon-search"></i>
          查询
        </button>
        <button
          class="btn2"
          v-if="permissoin.includes('add')"
          @click="
            modal = 1;
            modalState = 0;
          "
        >
          <i class="el-icon-plus"></i>
          新增
        </button>
      </p>
      <div class="table srcollstyle">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 99.9%"
          border
          max-height="700px"
        >
          <el-table-column type="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ (currentPage2 - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="项目名称"
            :show-overflow-tooltip="true"
            width="180"
          >
            <template slot-scope="scope">
              <span
                @click="handleCommand(scope.row)"
                class="colorblue"
                style="cursor: pointer"
                >{{ scope.row.name }}</span
              >
              <span v-if="scope.row.isPlace">(代理商)</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="areaName"
            label="项目区域"
            width="90"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ areaNameChange(scope.row.areaName) }}
            </template>
          </el-table-column>
          <el-table-column label="项目状态">
            <template slot-scope="scope">
              <span v-if="scope.row.schedule == 0">尚未计划</span>
              <span v-if="scope.row.schedule == 1">正在实施</span>
              <span v-if="scope.row.schedule == 2">正在运维</span>
              <span v-if="scope.row.schedule == 3">已竣工</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="constructionUnit"
            label="施工单位"
            :show-overflow-tooltip="true"
            width="140"
          >
          </el-table-column>
          <!-- <el-table-column prop="overTime" width="90" label="竣工时间">
            <template slot-scope="scope">
              <span>{{ timestampToTime1(scope.row.overTime) }}</span>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="createByName"
            :show-overflow-tooltip="true"
            width="110"
            label="业务员/代理商"
          >
          </el-table-column>
          <el-table-column prop="complexNumber" label="运维次数" sortable width='100'>
            <template slot-scope="scope">
              <span class="colorred" v-if="scope.row.complexNumber > 10">{{
                scope.row.complexNumber
              }}</span>
              <span
                class="colororange"
                v-if="
                  scope.row.complexNumber < 10 && scope.row.complexNumber > 5
                "
                >{{ scope.row.complexNumber }}</span
              >
              <span v-if="scope.row.complexNumber < 5">{{
                scope.row.complexNumber
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="运维状态">
            <template slot-scope="scope">
              <span
                class="colorred"
                v-if="getDaysBetween(scope.row.overTime) < 0"
                >已到期</span
              >
              <span
                class="colororange"
                v-if="
                  getDaysBetween(scope.row.overTime) < 3 &&
                  getDaysBetween(scope.row.overTime) > 0
                "
                >运维中</span
              >
              <span
                class="colorgreen"
                v-if="getDaysBetween(scope.row.overTime) > 3"
                >运维中</span>
            </template>
          </el-table-column>
          <el-table-column prop="overTime" width="100" label="到期时间" sortable>
            <template slot-scope="scope">
              <span
                class="colorred"
                v-if="getDaysBetween(scope.row.overTime) < 1"
                >{{ scope.row.overTime }}</span
              >
              <span
                class="colororange"
                v-if="
                  getDaysBetween(scope.row.overTime) < 3 &&
                  getDaysBetween(scope.row.overTime) > 1
                "
                >{{ scope.row.overTime }}</span
              >
              <span
                class="colorgreen"
                v-if="getDaysBetween(scope.row.overTime) > 3"
                >{{ scope.row.overTime }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="htje" label="销售金额"> 
            <template slot-scope="scope">
                  <span >￥{{fmoney(scope.row.htje)}}</span>
                    </template>
          </el-table-column>
          <el-table-column prop="ljhk" label="累计回款"> 
            <template slot-scope="scope">
                  <span >￥{{fmoney(scope.row.ljhk)}}</span>
                    </template>
          </el-table-column>
          <el-table-column prop="yszk" label="应收账款"> 
            <template slot-scope="scope">
                  <span >￥{{fmoney(scope.row.yszk)}}</span>
                    </template>
          </el-table-column>
          <el-table-column prop="htlr" label="利润"  :show-overflow-tooltip="true"
            width="98">
            <template slot-scope="scope">
                  <span class="colorred" v-if="fmoney(scope.row.htlr)<0">￥{{fmoney(scope.row.htlr)}}</span>                  
                  <span v-else>￥{{fmoney(scope.row.htlr)}}</span>
                    </template>
             </el-table-column>
          <el-table-column prop="lrl" label="利润率">
              <template slot-scope="scope">
                  <span class="colorred" v-if="scope.row.lrl<30">{{scope.row.lrl}}%</span>                  
                  <span v-else>{{scope.row.lrl}}%</span>
                    </template>
             </el-table-column>
          <el-table-column label="操作"  
            width="88">
            <template slot-scope="scope">
              <el-dropdown
                trigger="click"
                @command="handleCommand1"
                v-if="scope.row.schedule == 0"
              >
                <el-button type="primary" plain size="small">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    icon="el-icon-edit"
                    v-if="
                      permissoin.includes('edit') && scope.row.schedule == 0
                    "
                    :command="{ num: 2, data: scope.row }"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-delete"
                    v-if="permissoin.includes('del') && scope.row.schedule == 0"
                    :command="{ num: 3, data: scope.row }"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="money">
        <p>
          <span class="">本页合同总金额：</span>
          <span class="colorblue">￥{{ fmoney(indexForm.byzje) }}</span>
        </p>
        <p>
          <span>本页累计回款：</span
          ><span class="colorgreen">￥{{ fmoney(indexForm.byzhk) }}</span>
        </p>
        <p>
          <span>本页应收账款：</span
          ><span class="colorred">￥{{ fmoney(indexForm.byyszk) }}</span>
        </p>
        <p>
          <span>本页利润：</span
          ><span class="colorgreen">￥{{ fmoney(indexForm.byhtlr) }}</span>
        </p>
        <p>
          <span>累计合同总金额：</span
          ><span class="colorblue">￥{{ fmoney(indexForm.ljzje) }}</span>
        </p>
        <p>
          <span>累计总回款：</span
          ><span class="colorgreen">￥{{ fmoney(indexForm.ljzhk) }}</span>
        </p>
        <p>
          <span>累计应收账款：</span
          ><span class="colorred">￥{{ fmoney(indexForm.ljyszk) }}</span>
        </p>
        <p>
          <span>累计利润：</span
          ><span class="colorgreen">￥{{ fmoney(indexForm.ljhtlr) }}</span>
        </p>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage2"
          @size-change="handleSizeChange"
          prev-text="上一页"
          next-text="下一页"
          :page-size="pageSize"
          :page-sizes="[10, 50, 100, 500, 1000, 5000]"
          layout="total,sizes, prev, pager, next, jumper "
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="indexModal" v-if="indexModal == 1">
      <div class="inner">
        <p class="title">
          <span>新增记录</span>
          <span
            @click="indexModal=0;littleForm={linkman:'',phone:'',contactWay:'1',createTime:''};"
            class="pointer">
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="modal_item3">
          <div class="input_item">
            <p>联系人：</p>
            <el-input v-model="littleForm.linkman" size="mini"></el-input>
          </div>
          <div class="input_item">
            <p>联系电话：</p>
            <el-input v-model="littleForm.phone" size="mini"></el-input>
          </div>
          <div class="input_item">
            <p>联系方式：</p>
            <el-select v-model="littleForm.contactWay" size="mini">
              <el-option label="现场拜访" value="1"></el-option>
              <el-option label="线上回访" value="2"></el-option>
            </el-select>
          </div>
          <div class="input_item">
            <p>联系时间：</p>
            <el-date-picker
              size="mini"
              v-model="littleForm.createTime"
              :picker-options="pickerOptions1"
              placeholder="请选择联系时间,默认今天"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <div class="input_item">
            <p>联系内容：</p>
            <el-input
              type="textarea"
              v-model="littleForm.cause"
              size="mini"
            ></el-input>
          </div>
        </div>
        <div class="foot">
          <button class="finish" @click="over">完成</button>
        </div>
      </div>
    </div>
    <div class="modal" v-if="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-if="modalState == 0">新增项目</span>
          <span v-if="modalState == 1">修改项目信息</span>
          <span
            @click="
              ruleForm = {};
              modal = 0;
            "
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <el-form
            :model="ruleForm"
            :rules="rules"
            status-icon
            ref="ruleForm"
            label-width="80px"
            label-position="left"
          >
            <el-form-item label="项目名称:" prop="name">
              <el-input v-model="ruleForm.name" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="项目类型:" prop="name">
              <el-select v-model="ruleForm.orgType" size="mini">
                <el-option
                  v-for="item in option1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-show="ruleForm.orgType == 2"
              label="代理商:"
              prop="userId"
            >
              <el-select v-model="ruleForm.userId" size="mini">
                <el-option
                  v-for="item in option3"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联  系  人:" prop="headName">
              <el-input v-model="ruleForm.headName" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="联系方式:" prop="phone">
              <el-input v-model="ruleForm.phone" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="施工单位:" prop="constructionUnit">
              <el-input
                v-model="ruleForm.constructionUnit"
                size="mini"
              ></el-input>
            </el-form-item>
            <div class="mc_right">
              <div class="input_item">
                <span>*</span><span>项目地址</span>
                <el-input v-model="ruleForm.orgAddress" size="mini"></el-input>
              </div>
              <p class="jwd">
                <span class="gray999">请在地图上获取项目位置</span>
                <span>{{ ruleForm.coordinates }}</span>
              </p>
              <div class="map">
                <PtojectMap
                  :addressData="addressData"
                  @childByValue="childByValue"
                ></PtojectMap>
              </div>
            </div>
          </el-form>
        </div>
        <div class="bottom">
          <button class="btn_red" @click="onsubmit">保存</button>
          <button
            class="btn_gray"
            @click="
              ruleForm = {};
              modal = 0;
            "
          >
            取消
          </button>
        </div>
      </div>
    </div>
    <div class="modal6" v-if="modal == 6">
      <div class="inner11111">
        <p class="title">
          <span>{{ form.orgInfo.name }}</span>
          <span
            @click="
              modal = 0;
              form = {
                orgInfo: {},
                profitEstimates: {},
                costAccount: {},
                materialsStatistical: {},
                orderAndPlan: {},
                contractAmount: {},
              };
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div style="padding: 0 20px; max-height: 740px; overflow: auto">
          <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane
              label="基本信息"
              v-if="permissoin.includes('basic')"
              name="first"
            >
              <el-divider content-position="left">基本信息</el-divider>
              <div style="display: flex; justify-content: space-between">
                <div style="width: 400px">
                  <p class="sel">
                    <span class="spa">项目区域</span>
                    <span>{{ form.areaName }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">项目地址</span>
                    <span>{{ form.orgInfo.orgAddress }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">施工单位</span>
                    <span>{{ form.orgInfo.constructionUnit }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">单位联系人</span>
                    <span>{{ form.orgInfo.headName }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">联系电话</span>
                    <span>{{ form.orgInfo.phone }}</span>
                  </p>
                </div>
                <div style="width: 400px">
                  <p class="sel">
                    <span class="spa">业务员</span>
                    <span>{{ form.orgInfo.updater }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">联系电话</span>
                    <span>{{ form.orgInfo.updaterByPhone }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">运维状态</span>
                    <span v-if="form.orgInfo.isOver" class="colorred"
                      >已到期</span
                    >
                    <span v-else class="colorgreen">运维中</span>
                  </p>
                  <p class="sel">
                    <span class="spa">运维到期时间</span>
                    <span>{{ timestampToTime1(form.orgInfo.overTime) }}</span>
                  </p>
                  <p class="sel" style="position: relative">
                    <span class="spa">备注</span>
                    <!-- <span v-show="remarkstate == 0">{{ remarks }}</span> -->
                    <el-tooltip
                      v-show="remarkstate == 0"
                      class="item"
                      effect="light"
                      :content="remarks"
                      placement="top-start"
                    >
                      <span
                        style="
                          display: inline-block;
                          max-width: 250px;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                        "
                        >{{ remarks }}</span
                      >
                    </el-tooltip>

                    <el-input
                      style="
                        position: absolute;
                        top: 0px;
                        left: 80px;
                        z-index: 10000;
                      "
                      type="textarea"
                      v-show="remarkstate == 1"
                      v-model="remarks"
                      clearable
                      size="mini"
                    ></el-input>
                    <span class="ii">
                      <i
                        class="el-icon-edit"
                        @click="remarkstateChange(1)"
                        v-show="remarkstate == 0"
                      ></i>
                      <i
                        style="
                          position: absolute;
                          top: 0px;
                          left: 370px;
                          z-index: 100000;
                        "
                        class="el-icon-check"
                        @click="remarkstateChange(0)"
                        v-show="remarkstate == 1"
                      ></i>
                    </span>
                  </p>
                </div>
                <div class="map">
                  <PtojectMap
                    :addressData="addressData"
                    @childByValue="childByValue"
                  ></PtojectMap>
                </div>
              </div>
              <div>
                <el-divider content-position="left">回款进度</el-divider>
                <p class="sel">
                  <span class="spa">合同总额</span>
                  <span class="colorblue">￥{{ form1.repaymentTotal }}</span>
                </p>
                <el-progress
                  :text-inside="true"
                  :stroke-width="16"
                  :percentage="form1.repaymentRate"
                ></el-progress>
                <div
                  style="
                    margin-top: 14px;
                    display: flex;
                    justify-content: space-between;
                    width: 600px;
                  "
                >
                  <p class="sel">
                    <span class="spa">累计回款</span>
                    <span class="colorgreen">￥{{ form1.repaymentPaid }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">应收账款</span>
                    <span class="colorred">￥{{ form1.repaymentTotal }}</span>
                  </p>
                </div>

                <el-table :data="tableDatahk" style="width: 1200px">
                  <el-table-column type="index" label="排序" width="60">
                  </el-table-column>
                  <el-table-column prop="userName" label="回款人">
                  </el-table-column>
                  <el-table-column prop="amount" label="回款金额">
                  </el-table-column>
                  <el-table-column prop="createTime" label="收款日期">
                  </el-table-column>
                  <el-table-column prop="merchantName" label="收款方式">
                    <template slot-scope="scope">
                      <span v-if="scope.row.payType == 3">对公帐户</span>
                      <span v-if="scope.row.payType == 1">现金 </span>
                      <span v-if="scope.row.payType == 2">电子汇款</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="bankName"
                    label="收款账户"
                    width="160"
                    :show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="company"
                    label="付款单位"
                    width="200"
                    :show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column prop="state" label="状态">
                    <template slot-scope="scope">
                      <span v-if="scope.row.state == 3">驳回</span>
                      <span v-if="scope.row.state == 1">已到账</span>
                      <span v-if="scope.row.state == 2">待审核</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div>
                <el-divider content-position="left">开票进度</el-divider>
                <p class="sel">
                  <span class="spa">合同总额</span>
                  <span class="colorblue">￥{{ form1.total }}</span>
                </p>
                <el-progress
                  :text-inside="true"
                  :stroke-width="16"
                  :percentage="form1.invoiceRate"
                ></el-progress>
                <div
                  style="
                    margin-top: 14px;
                    display: flex;
                    justify-content: space-between;
                    width: 600px;
                  "
                >
                  <p class="sel">
                    <span class="spa">累计开票</span>
                    <span class="colorgreen">￥{{ form1.invoicePaid }}</span>
                  </p>
                  <p class="sel">
                    <span class="spa">剩余票面</span>
                    <span class="colorred">￥{{ form1.invoiceTotal }}</span>
                  </p>
                </div>
                <el-table :data="tableDatakp" style="width: 1200px">
                  <el-table-column type="index" label="排序" width="60">
                  </el-table-column>
                  <el-table-column prop="amount" label="开票金额">
                  </el-table-column>
                  <el-table-column prop="createTime" label="开票日期">
                  </el-table-column>
                  <el-table-column prop="merchantName" label="发票类型">
                    <template slot-scope="scope">
                      <span v-if="scope.row.invoiceType == 1">普通发票</span>
                      <span v-if="scope.row.invoiceType == 2"
                        >增值税专用发票</span
                      >
                      （{{ scope.row.taxRate }}%）
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="company"
                    label="出票单位"
                    width="200"
                    :show-overflow-tooltip="true"
                  >
                  </el-table-column>
                  <el-table-column prop="merchantName" label="状态">
                    <template slot-scope="scope">
                      <span v-if="scope.row.state == 3">驳回</span>
                      <span v-if="scope.row.state == 4">已作废</span>
                      <span v-if="scope.row.state == 1">已开票</span>
                      <span v-if="scope.row.state == 2">待开票</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="计划详情"
              v-if="permissoin.includes('contract')"
              name="second"
            >
              <p class="sel">
                <span class="spa">合同金额</span>
                <span class="colorblue">{{ form.contractAmount.sum }}</span>
                <span class="gray666"
                  >（硬件材料:￥{{ form.contractAmount.yjcl }}, 系统软件:￥{{
                    form.contractAmount.xtrj
                  }},其他材料:￥{{ form.contractAmount.qtfw }}, ）</span
                >
              </p>
              <p class="sel">
                <span class="spa">累计回款</span>
                <span class="colorgreen">{{ form.contractAmount.ljhk }}</span>
              </p>
              <p class="sel">
                <span class="spa">应收账款</span>
                <span class="colorred">{{ form.contractAmount.yszk }}</span>
              </p>
              <el-collapse v-model="activeNames">
                <el-collapse-item
                  v-for="(item, index) in contractableData"
                  :key="index"
                  :name="index"
                >
                  <template slot="title">
                    <p>
                      <span
                        >{{ item.createTime }} ·
                        {{ item.orderType == 1 ? "初装计划" : "补充计划" }} ·
                        ￥{{ item.allPrice }}</span
                      >
                    </p>
                  </template>
                  <p>
                    硬件材料总计：<span class="colorblue"
                      >￥{{ item.yjcb }}</span
                    >，系统软件总计：<span class="colorblue"
                      >￥{{ item.xtrj }}</span
                    >
                    ，其他服务：<span class="colorblue">￥{{ item.qtfw }}</span>
                  </p>
                  <el-table :data="item.item" style="width: 1200px">
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <el-table-column
                      prop="productName"
                      label="产品名"
                      width="200"
                      :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column prop="operNumber" label="数量">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价（元）">
                    </el-table-column>
                    <el-table-column prop="taxUnitPrice" label="含税单价（元）">
                    </el-table-column>
                    <el-table-column prop="taxMoney" label="税金（元）">
                    </el-table-column>
                    <el-table-column prop="taxLastMoney" label="小计（元）">
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane
              label="材料统计"
              v-if="permissoin.includes('material')"
              name="third"
            >
              <p class="sel">
                <span class="spa">计划用量</span>
                <span class="colorblue">{{
                  form.materialsStatistical.jhyl
                }}</span>
              </p>
              <p class="sel">
                <span class="spa">实际用量</span>
                <span class="colorgreen">{{
                  form.materialsStatistical.sjyl
                }}</span>
              </p>
              <el-collapse v-model="activeNames">
                <el-collapse-item
                  v-for="(item, index) in contractableMateria"
                  :key="index"
                  :name="index"
                >
                  <template slot="title">
                    <p>
                      <span
                        >{{ item.createTime }} ·
                        <span v-if="item.orderType == 1">初装计划</span>
                        <span v-if="item.orderType == 2">补充计划</span>
                        <span v-if="item.orderType == 1">其他计划</span>
                        · ￥{{ item.allPrice }}</span
                      >
                    </p>
                  </template>
                  <p>
                    <span style="margin-right: 20px">
                      <span class="gray999">计划使用:</span>
                      {{ item.planNumber }}</span
                    >
                    <span style="margin-right: 20px">
                      <span class="gray999">实际使用:</span>
                      <span class="colorgreen">{{ item.actualNumber }}</span>
                    </span>
                    <!-- <span
                      class="colorblue"
                      style="cursor: pointer; margin-right: 20px"
                      >查看出库清单</span
                    >
                    <span class="colorblue" style="cursor: pointer"
                      >查看实际使用清单</span
                    > -->
                  </p>
                  <el-table :data="item.stockList" style="width: 1200px">
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <el-table-column
                      prop="productName"
                      label="产品名"
                      :show-overflow-tooltip="true"
                      width="180"
                    >
                    </el-table-column>
                    <el-table-column prop="planNumber" label="计划数量">
                    </el-table-column>
                    <el-table-column prop="applyNumber" label="申领数量">
                    </el-table-column>
                    <el-table-column prop="actualNumber" label="实际用量">
                    </el-table-column>
                    <el-table-column
                      prop="requisitionNumber"
                      label="实际出库数量"
                    >
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane
              label="成本核算"
              v-if="permissoin.includes('cost')"
              name="fourth"
            >
              <p class="sel">
                <span class="spa">总成本</span>
                <span class="colorblue">￥{{ costForm.allCost }}</span>
              </p>
              <p class="sel">
                <span class="spa">利润估算</span>
                <span class="colorblue">￥{{ costForm.grossProfit }}</span>
              </p>
              <p class="sel">
                <span class="spa">毛利率</span>
                <span class="colorblue">{{ costForm.rate }}</span>
              </p>
              <el-collapse v-model="activeNames">
                <el-collapse-item
                  :title="'硬件成本￥' + form.costAccount.yjcb"
                  name="1"
                >
                  <!-- <p class="sel">
                    <span>金额：</span>
                    <span class="colorblue">{{ form.costAccount.yjcb }}</span>
                  </p> -->
                  <el-table :data="costForm.hardwareList" style="width: 1200px">
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <el-table-column
                      prop="productName"
                      label="产品名"
                      width="140"
                      :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column prop="num" label="数量"> </el-table-column>
                    <el-table-column prop="handsPersonName" label="实施人">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价（元）">
                    </el-table-column>
                    <el-table-column prop="subtotal" label="小计（元）">
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
                <el-collapse-item  name="2" :title="'软件成本￥' + costForm.softwareCost">
                  <!-- <p class="sel">
                    <span>金额：</span>
                    <span class="colorgreen"
                      >{{ costForm.softwareCost }}</span
                    >
                  </p> -->
                  <el-table :data="costForm.softwareList" style="width: 1200px">
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      label="软件系统"
                      width="140"
                      :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column prop="num" label="数量">
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价（元）">
                    </el-table-column>
                    <el-table-column prop="allPrice" label="小计（元）">
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
                <el-collapse-item
                  :title="'运维成本￥' + form.costAccount.yycb"
                  name="3"
                >
                  <!-- <p class="sel">
                    <span>金额：</span>
                    <span class="colorred">{{ form.costAccount.yycb }}</span>
                  </p> -->
                  <el-table
                    :data="costForm.operationList"
                    style="width: 1200px"
                  >
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <!-- <el-table-column prop="deviceNum" label="类型">
                      维修
                    </el-table-column> -->
                    <el-table-column prop="completionTime" width="190" :show-overflow-tooltip="true" label="实施日期">
                    </el-table-column>
                    <el-table-column prop="handsPersonName" label="实施人">
                    </el-table-column>
                    <el-table-column
                      prop="实用硬件"
                      label="实用硬件"
                      width="220"
                      :show-overflow-tooltip="true">
                      <!-- 平台人脸1台（总价：￥1300） -->
                    </el-table-column>

                    <el-table-column prop="差旅费" label="差旅费">
                    </el-table-column>
                    <el-table-column prop="交通费" label="交通费">
                    </el-table-column>
                    <el-table-column prop="其他费用" label="其他费用">
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
                <el-collapse-item
                  :title="'商务成本￥' + form.costAccount.swcb"
                  name="4"
                >
                  <!-- <p class="sel">
                    <span>金额：</span>
                    <span class="colorred">{{ form.costAccount.swcb }}</span>
                  </p> -->
                  <el-table :data="costForm.businessList" style="width: 1200px">
                    <el-table-column type="index" label="排序" width="60">
                    </el-table-column>
                    <el-table-column
                      prop="productName"
                      label="商务名目"
                      width="140"
                      :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column prop="creatorName" label="创建人">
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建日期">
                    </el-table-column>
                    <el-table-column prop="taxLastMoney" label="总价（元）">
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane
              label="安装计划"
              v-if="permissoin.includes('install')"
              name="seventh"
            >
              <p class="sel">
                <span class="spa">计划次数</span>
                <span class="colorred">{{ tableDataorder.length }}</span>
              </p>
              <el-table :data="tableDataorder" style="width: 1200px">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <div style="display: flex">
                      <div class="table_item">
                        <div class="jstitle">
                          <span>名称</span>
                          <span>数量</span>
                          <span>税率（%）</span>
                          <span>含税单价</span>
                          <span>总价</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.硬件材料 != null"
                        >
                          硬件材料
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.硬件材料"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.软件系统 != null"
                        >
                          软件系统
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.软件系统"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.其他服务 != null"
                        >
                          其他服务
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.其他服务"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                      </div>
                      <div class="table_item">
                        <p class="table_item_title">现场照片</p>
                        <el-image
                          v-for="item in props.row.fileArr"
                          :key="item"
                          style="width: 80px; height: 100px; margin-right: 20px"
                          :src="item"
                          :preview-src-list="props.row.fileArr"
                        >
                        </el-image>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column type="index" label="排序" width="60">
                </el-table-column>
                <el-table-column label="计划类型">
                  <template slot-scope="scope">
                    <span v-if="scope.row.orderType == 1">初始计划</span>
                    <span v-if="scope.row.orderType == 2">补充计划</span>
                  </template>
                </el-table-column>
                <el-table-column prop="materialDate" label="实施日期">
                </el-table-column>
                <el-table-column prop="creatorName" label="申请人">
                </el-table-column>
                <el-table-column prop="handsPersonName" label="实施人">
                </el-table-column>
                <el-table-column prop="merchantName" label="计划状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" class="colorblue"
                      >待审核</span
                    >
                    <span v-if="scope.row.status == 2" class="colorred"
                      >驳回</span
                    >
                    <span v-if="scope.row.status == 3" class="colorblue"
                      >待实施</span
                    >
                    <span v-if="scope.row.status == 4" class="colorblue"
                      >待分配</span
                    >
                    <span v-if="scope.row.status == 0" class="colorgreen"
                      >已完成</span
                    >
                    <span v-if="scope.row.status == 5" class="colorblue"
                      >待领取</span
                    >
                    <span v-if="scope.row.status == 9" class="colorred"
                      >安装退回</span
                    >
                    <span v-if="scope.row.status == 8" class="colorred"
                      >安装失败</span
                    >
                    <span v-if="scope.row.status == 7" class="colorred"
                      >安装待确认</span
                    >
                    <span v-if="scope.row.status == 6" class="colorblue"
                      >待出库</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  width="170"
                  :show-overflow-tooltip="true"
                  prop="materialRemark"
                  label="安装结果"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="商务计划"
              v-if="permissoin.includes('business')"
              name="eighth"
            >
              <p class="sel">
                <span class="spa">计划次数</span>
                <span class="colorred">{{ tableDatabusiness.length }}</span>
              </p>
              <el-table :data="tableDatabusiness" style="width: 1200px">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <div style="display: flex">
                      <div class="table_item">
                        <div class="jstitle">
                          <span>商务名目</span>
                          <span>数量</span>
                          <!-- <span>税率（%）</span> -->
                          <span>含税单价</span>
                          <span>总价</span>
                        </div>
                        <div
                          class="jstitle"
                          v-for="item in props.row.itemList"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <!-- <span>{{item.taxRate*100}}</span> -->
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column type="index" label="排序" width="60">
                </el-table-column>
                <el-table-column
                  width="150"
                  :show-overflow-tooltip="true"
                  prop="contractorsName"
                  label="参建单位"
                >
                </el-table-column>
                <el-table-column prop="linkman" label="联系人">
                </el-table-column>
                <el-table-column prop="phone" label="联系电话">
                </el-table-column>
                <el-table-column prop="materialDate" label="实施日期">
                </el-table-column>
                <el-table-column prop="creatorName" label="申请人">
                </el-table-column>
                <el-table-column prop="merchantName" label="计划状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" class="colorblue"
                      >待审核</span
                    >
                    <span v-if="scope.row.status == 2" class="colorred"
                      >驳回</span
                    >
                    <span v-if="scope.row.status == 0" class="colorgreen"
                      >已完成</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="运营计划"
              v-if="permissoin.includes('operate')"
              name="tenth"
            >
              <p class="sel">
                <span class="spa">计划次数</span>
                <span class="colorred">{{ tableDataoperation.length }}</span>
              </p>
              <el-table :data="tableDataoperation" style="width: 1200px">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <div style="display: flex">
                      <div class="table_item">
                        <div class="jstitle">
                          <span>名称</span>
                          <span>数量</span>
                          <span>税率（%）</span>
                          <span>含税单价</span>
                          <span>总价</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.itemList != null"
                        >
                          其他服务
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.itemList"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                      </div>
                      <div class="table_item">
                        <p class="table_item_title">现场照片</p>
                        <el-image
                          v-for="item in props.row.fileArr"
                          :key="item"
                          style="width: 80px; height: 100px; margin-right: 20px"
                          :src="item"
                          :preview-src-list="props.row.fileArr"
                        >
                        </el-image>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column type="index" label="排序" width="60">
                </el-table-column>

                <el-table-column prop="materialDate" label="实施日期">
                </el-table-column>
                <el-table-column prop="creatorName" label="申请人">
                </el-table-column>
                <el-table-column prop="handsPersonName" label="实施人">
                </el-table-column>
                <el-table-column
                  prop="description"
                  label="问题描述"
                  width="150"
                  :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column prop="materialName" label="维护对象">
                </el-table-column>
                <el-table-column label="支付方式">
                  <template slot-scope="scope">
                    <span v-if="scope.row.payWay == 1">现金支付</span>
                    <span v-if="scope.row.payWay == 2">线上支付</span>
                  </template>
                </el-table-column>
                <el-table-column prop="receivable" label="现场实收">
                </el-table-column>
                <el-table-column prop="merchantName" label="计划状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" class="colorblue"
                      >待审核</span
                    >
                    <span v-if="scope.row.status == 2" class="colorred"
                      >驳回</span
                    >
                    <span v-if="scope.row.status == 3" class="colorgreen"
                      >待指派</span
                    >
                    <span v-if="scope.row.status == 4" class="colorblue"
                      >待实施</span
                    >
                    <span v-if="scope.row.status == 5" class="colorred"
                      >已退单</span
                    >
                    <span v-if="scope.row.status == 0" class="colorgreen"
                      >已完成</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="cause"
                  width="140"
                  :show-overflow-tooltip="true"
                  label="处理结果"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="维修计划"
              v-if="permissoin.includes('repair')"
              name="elevenwwth"
            >
              <p class="sel">
                <span class="spa">计划次数</span>
                <span class="colorred">{{ tableDatamaintain.length }}</span>
              </p>
              <el-table :data="tableDatamaintain" style="width: 1200px">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <div style="display: flex">
                      <div class="table_item">
                        <div class="jstitle">
                          <span>名称</span>
                          <span>数量</span>
                          <span>税率（%）</span>
                          <span>含税单价</span>
                          <span>总价</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.硬件材料 != null"
                        >
                          硬件材料
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.硬件材料"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                        <p
                          class="table_item_title"
                          v-show="props.row.其他服务 != null"
                        >
                          其他服务
                        </p>
                        <div
                          class="jstitle"
                          v-for="item in props.row.其他服务"
                          :key="item.productName"
                        >
                          <span>{{ item.productName }}</span>
                          <span>{{ item.operNumber }}</span>
                          <span>{{ item.taxRate * 100 }}</span>
                          <span>{{ item.taxUnitPrice }}</span>
                          <span>{{ item.taxLastMoney }}</span>
                        </div>
                      </div>
                      <div class="table_item">
                        <p class="table_item_title">现场照片</p>
                        <el-image
                          v-for="item in props.row.fileArr"
                          :key="item"
                          style="width: 80px; height: 100px; margin-right: 20px"
                          :src="item"
                          :preview-src-list="props.row.fileArr"
                        >
                        </el-image>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column type="index" label="排序" width="60">
                </el-table-column>

                <el-table-column prop="materialDate" label="实施日期">
                </el-table-column>
                <el-table-column prop="creatorName" label="申请人">
                </el-table-column>
                <el-table-column prop="handsPersonName" label="实施人">
                </el-table-column>
                <el-table-column
                  prop="description"
                  label="维修原因"
                  width="150"
                  :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column prop="materialName" label="维修对象">
                </el-table-column>
                <el-table-column prop="deviceNum" label="支付方式">
                  <template slot-scope="scope">
                    <span v-if="scope.row.payWay == 1">现金支付</span>
                    <span v-if="scope.row.payWay == 2">线上支付</span>
                  </template>
                </el-table-column>
                <el-table-column prop="receivable" label="现场实收">
                </el-table-column>
                <el-table-column prop="merchantName" label="计划状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" class="colorblue"
                      >待审核</span
                    >
                    <span v-if="scope.row.status == 2" class="colorred"
                      >驳回</span
                    >
                    <span v-if="scope.row.status == 3" class="colorblue"
                      >待实施</span
                    >
                    <span v-if="scope.row.status == 4" class="colorblue"
                      >待分配</span
                    >
                    <span v-if="scope.row.status == 0" class="colorgreen"
                      >已完成</span
                    >
                    <span v-if="scope.row.status == 5" class="colorblue"
                      >待领取</span
                    >
                    <span v-if="scope.row.status == 9" class="colorblue"
                      >安装退回</span
                    >
                    <span v-if="scope.row.status == 6" class="colorblue"
                      >待出库</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="cause"
                  label="处理结果"
                  width="140"
                  :show-overflow-tooltip="true"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="安心计划"
              v-if="permissoin.includes('peace')"
              name="tenqqqth"
            >
              <p class="sel">
                <span class="spa">计划次数</span>
                <span class="colorred">{{ tableDatarenew.length }}</span>
              </p>
              <el-table :data="tableDatarenew" style="width: 1200px">
                <el-table-column type="index" label="排序" width="60">
                </el-table-column>
                <el-table-column
                  prop="deviceNum"
                  label="计划名目"
                  width="140"
                  :show-overflow-tooltip="true"
                >
                  运维服务一年
                </el-table-column>
                <el-table-column prop="createTime" label="创建日期">
                  <template slot-scope="scope">{{
                    timestampToTime1(scope.row.createTime)
                  }}</template>
                </el-table-column>
                <el-table-column prop="creatorName" label="申请人">
                </el-table-column>
                <el-table-column prop="merchantName" label="计划状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 1" class="colorblue"
                      >待审核</span
                    >
                    <span v-if="scope.row.status == 2" class="colorred"
                      >驳回</span
                    >
                    <span v-if="scope.row.status == 0" class="colorgreen"
                      >已完成</span
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="num" label="数量">
                  <template slot-scope="scope">
                    {{
                      scope.row.itemList.length == 1
                        ? scope.row.itemList[0].operNumber
                        : ""
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="p" label="单价">
                  <template slot-scope="scope">
                    {{
                      scope.row.itemList.length == 1
                        ? scope.row.itemList[0].taxUnitPrice
                        : ""
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="al" label="含税总价（元）">
                  <template slot-scope="scope">
                    {{
                      scope.row.itemList.length == 1
                        ? scope.row.itemList[0].taxLastMoney
                        : ""
                    }}
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane
              label="往来记录"
              name="tenqqqthqq"
              v-if="permissoin.includes('Intercourse')"
            >
              <p class="sel">
                <span class="spa">联系记录</span>
                <span class="colorred" style="margin-right: 20px">{{
                  activities.length
                }}</span>
                <button
                  class="btn2"
                  v-if="permissoin.includes('IntercourseAdd')"
                  style="height: 26px; line-height: 26px"
                  @click="openLittleModal"
                >
                  <i class="el-icon-plus"></i>
                  新增
                </button>
              </p>
              <el-timeline>
                <el-timeline-item
                  v-for="(item, index) in activities"
                  :key="index"
                >
                  <p>联系人：{{ item.linkman }} · {{ item.phone }}</p>
                  <p>
                    联系方式：{{
                      item.contactWay == 1 ? "现场拜访" : "线上回访"
                    }}
                  </p>
                  <p>联系内容：{{ item.cause }}</p>
                  <p class="gray999" style="margin-top: 12px">
                    {{ item.createTime }} · {{ item.userName }}
                  </p>
                </el-timeline-item>
              </el-timeline>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  wxGetOrgList1,
  sysOrgEdit,
  orderanddistributor,
  recordAdd,
  getUserList,
  sysOrgDel,
  projectStatistics,
  addSysOrgByWx,
  projectStatisticsMateria,
  sysOrgUpdate,
  getProgressInfo,
  merchantGetList,
  cost,
  recordList,
  orderbusiness,
  ordermaintain,
  orderoperation,
  orderrenew,
  wxGetOrgInfo,
} from "@/api/apis.js";
import PtojectMap from "@/components/PtojectMap.vue";
import {
  timestampToTime1,
  areaNameChange,
  getNowTime1,fmoney
} from "@/utils/getTime.js";
export default {
  data() {
    var checkIdmobile = (rule, value, cb) => {
      if (/^1[3-9]\d{9}$/.test(value)) {
        return cb();
      }
      cb(new Error("您输入的电话号码不是有效格式"));
    };
    return {
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now(); //当天之前的时间可选
        },
      },
      form: {
        orgInfo: {},
        profitEstimates: {},
        costAccount: {},
        materialsStatistical: {},
        orderAndPlan: {},
        contractAmount: {},
      },
      fmoney,
      littleForm: { linkman: "", phone: "", contactWay: "1",createTime:'' },
      indexModal: 0,
      loading: false,
      costForm: {},
      contractableData: [],
      contractableMateria: [],
      indexForm: {},
      activities: [],
      modalState: 0,
      info: 1,
      deleteId: "",
      areaNameChange,
      form1: {},
      remarks: "",
      remarkstate: 0,
      tableDatahk: [],
      tableDatakp: [],
      tableDataorder: [],
      tableDatabusiness: [],
      tableDataoperation: [],
      tableDatarenew: [],
      tableDatamaintain: [],
      timestampToTime1,
      currentPage2: 1,
      dialogVisible: false,
      pageSize: 10,
      hide: 1,
      activeNames: ["1"],
      keysArr: [],
      modal: 0,
      total: 0,
      permissoin: [],
      tableData: [],
      searchForm: {},
      ruleForm: {
        pwd: 123456,
        date1: "",
        date2: "",
      },
      activeName: "first",
      option3: [],
      addressData: {},
      tableData1: [{ date: 0 }],
      detailP: 1,
      option1: [
        {
          value: 1,
          label: "自营项目",
        },
        {
          value: 2,
          label: "代理商项目",
        },
      ],
      optionarea: [
        {
          value: "1",
          label: "新都区",
        },
        {
          value: "2",
          label: "双流区",
        },
      ],
      optionpay: [
        {
          value: "1",
          label: "已结清",
        },
        {
          value: "2",
          label: "收款中",
        },
      ],
      option2: [
        {
          value: "0",
          label: "尚未计划",
        },
        {
          value: "1",
          label: "正在实施",
        },
        {
          value: "2",
          label: "正在运维",
        },
        {
          value: "3",
          label: "已竣工",
        },
      ],
      optionoperate: [
        {
          value: "1",
          label: "1个月内到期",
        },
        {
          value: "2",
          label: "3个月内到期",
        },
        {
          value: "3",
          label: "6个月内到期",
        },

        {
          value: "5",
          label: "6个月以上到期",
        },
        {
          value: "4",
          label: "已到期",
        },
      ],
      option30: [],
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      rules: {
        name: [{ required: true, message: "请输入组织名称", trigger: "blur" }],
        orgType: [
          { required: true, message: "请选择项目类型", trigger: "change" },
        ],
        schedule: [
          { required: true, message: "请选择项目进度", trigger: "change" },
        ],
        headName: [
          { required: true, message: "请输入负责人姓名", trigger: "blur" },
        ],
        constructionUnit: [
          { required: true, message: "请输入施工单位", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入负责人联系电话", trigger: "blur" },
          { validator: checkIdmobile, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getData() {
      this.loading = true;
      let overTime,
        overTimeMore = "";
      switch (this.searchForm.overTime) {
        case "1":
          // overTime=new Date(new Date().toLocaleDateString()).getTime()+30 * 24 * 3600 * 1000 * 1;
          overTime = this.ChooseType(1);
          break;
        case "2":
          overTime = this.ChooseType(3);
          break;
        case "3":
          overTime = this.ChooseType(6);
          break;
        case "4":
          overTime = this.ChooseType(0);
          break;
        case "5":
          overTimeMore = this.ChooseType(6);
          break;
        default:
          overTime = "";
          overTimeMore = "";
          break;
      }
      let data = {
        name: this.searchForm.name,
        areaName: this.searchForm.areaName,
        userIds: this.searchForm.userId ? this.searchForm.userId.join(",") : "",
        overTime: this.timestampToTime1(overTime),
        overTimeMore: this.timestampToTime1(overTimeMore),
        schedule: this.searchForm.schedule,
        pageSize: this.pageSize,
        pageNum: this.currentPage2,
      };
      wxGetOrgList1(data).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.tableData = res.data.list.list.sort(function (a, b) {
            if (a.complexNumber < b.complexNumber) {
              return 1;
            } else if (a.complexNumber > b.complexNumber) {
              return -1;
            } else {
              return 0;
            }
          });
          this.total = res.data.list.total - 0;
          this.indexForm = res.data;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },

    openLittleModal() {
      this.littleForm.linkman = this.form.orgInfo.headName;
      this.littleForm.phone = this.form.orgInfo.phone;
      this.littleForm.contactWay = "1";
      this.littleForm.createTime=getNowTime1()
      this.indexModal = 1;
    },
    over() {
      let data = {
        linkman: this.littleForm.linkman,
        phone: this.littleForm.phone,
        contactWay: this.littleForm.contactWay,
        cause: this.littleForm.cause,
        createTime: this.littleForm.createTime || getNowTime1(),
        projectId: this.indexForm.id,
      };
      recordAdd(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.indexModal = 0;
          this.littleForm = {linkman:'',phone:'',contactWay:'1',createTime:''};
          this.getRecordList();
        } else {
          this.$message.error(res.data.info);
        }
      });
    },
    getRecordList() {
      recordList({ projectId: this.indexForm.id }).then((res) => {
        if (res.code == 200) {
          this.activities = res.data;
        } else {
          this.activities = [];
        }
      });
    },
    remarkstateChange(i) {
      if (i == 1) {
        this.remarkstate = 1;
      } else if (i == 0) {
        sysOrgUpdate({ id: this.indexForm.id, remarks: this.remarks }).then(
          (res) => {
            if (res.code == 200) {
              this.remarkstate = 0;
              this.$message({
                type: "success",
                message: "修改成功",
              });
            } else {
              this.$message.error(res.data.msg);
            }
          }
        );
      }
    },
    ChooseType(i) {
      var date = new Date();
      //日期转文本方式一：
      //  let str = date.format("yyyy-MM-dd");
      var year = date.getFullYear(); //年
      var month = date.getMonth() + i + 1; //月 +6个月  因为js里month从0开始，所以要加1
      if (month > 12) {
        year++;
        month -= 12;
      }
      if (month < 10) {
        month = "0" + month;
      }
      var date2 = new Date(year, month, 0); //新的年月
      var day1 = date.getDate();
      var day2 = date2.getDate();
      if (day1 > day2) {
        //防止+6月后没有31天
        day1 = day2;
      }
      let str = year + "-" + month + "-" + day1;
      return str;
    },
    getDaysBetween(dateString1) {
      var startDate = new Date();
      var endDate = Date.parse(dateString1);
      var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000 * 30);
      return days;
    },
    childByValue(v) {
      this.ruleForm.latitude = v.lat;
      this.ruleForm.longitude = v.lng;
      this.ruleForm.orgAddress = v.address;
      this.ruleForm.areaName = v.areaName;
      this.ruleForm.coordinates =
        this.ruleForm.longitude + "," + this.ruleForm.latitude;
      this.$forceUpdate();
    },
    onsubmit() {
      if (this.modalState == 0) {
        let data = {};
        if (this.ruleForm.orgType == 1) {
          data = {
            name: this.ruleForm.name,
            areaName: this.ruleForm.areaName,
            orgType: this.ruleForm.orgType,
            headName: this.ruleForm.headName,
            phone: this.ruleForm.phone,
            constructionUnit: this.ruleForm.constructionUnit,
            orgAddress: this.ruleForm.orgAddress,
            coordinates: this.ruleForm.coordinates,
          };
        } else {
          data = {
            name: this.ruleForm.name,
            orgType: this.ruleForm.orgType,
            headName: this.ruleForm.headName,
            areaName: this.ruleForm.areaName,
            phone: this.ruleForm.phone,
            constructionUnit: this.ruleForm.constructionUnit,
            orgAddress: this.ruleForm.orgAddress,
            coordinates: this.ruleForm.coordinates,
            userId: this.ruleForm.userId,
          };
        }
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            addSysOrgByWx(data).then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "新增成功",
                });
                this.getData();
                this.modal = 0;
                this.ruleForm = {};
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            return false;
          }
        });
      } else if (this.modalState == 1) {
        let data = {};
        if (this.ruleForm.orgType == 1) {
          data = {
            name: this.ruleForm.name,
            areaName: this.ruleForm.areaName,
            orgType: this.ruleForm.orgType,
            headName: this.ruleForm.headName,
            phone: this.ruleForm.phone,
            constructionUnit: this.ruleForm.constructionUnit,
            orgAddress: this.ruleForm.orgAddress,
            coordinates: this.ruleForm.coordinates,
            id: this.deleteId,
          };
        } else {
          data = {
            name: this.ruleForm.name,
            orgType: this.ruleForm.orgType,
            headName: this.ruleForm.headName,
            areaName: this.ruleForm.areaName,
            phone: this.ruleForm.phone,
            constructionUnit: this.ruleForm.constructionUnit,
            orgAddress: this.ruleForm.orgAddress,
            coordinates: this.ruleForm.coordinates,
            userId: this.ruleForm.userId,
            id: this.deleteId,
          };
        }
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            sysOrgEdit(data).then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "修改成功",
                });
                this.getData();
                this.modal = 0;
                this.ruleForm = {};
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    deletePro() {
      sysOrgDel({ id: this.deleteId }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.dialogVisible = false;
          this.getData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    close() {
      this.modal = 0;
    },
    handleCommand1(d) {
      this.deleteId = d.data.id;
      if (d.num == 2) {
        this.ruleForm = JSON.parse(JSON.stringify(d.data));
        this.modalState = 1;
        this.modal = 1;
        this.addressData = {
          coordinates: d.data.coordinates,
          type: 2,
        };
      } else if (d.num == 3) {
        this.dialogVisible = true;
      }
    },
    handleCommand(d) {
      this.indexForm.id = d.id;
      let data = { id: d.id };
      let data2 = { projectId: d.id };
      wxGetOrgInfo(data).then((res) => {
        if (res.code == 200) {
          this.modalState = 1;
          this.form = res.data;
          this.remarks = res.data.orgInfo.remarks;
          this.addressData = {
            coordinates: res.data.orgInfo.coordinates,
            type: 1,
          };
          this.modal = 6;
        } else {
          this.$message.error(res.data.msg);
        }
      });
      getProgressInfo({ id: d.id, type: "1" }).then((res) => {
        if (res.code == 200) {
          this.form1 = res.data;
          this.tableDatahk = res.data.repayment || [];
          this.tableDatakp = res.data.invoice || [];
        } else {
          this.$message.error(res.data.msg);
        }
      });
      orderanddistributor({ projectId: d.id }).then((res) => {
        if (res.code == 200) {
          // this.tableDataorder= res.data.list
          this.tableDataorder = res.data.map((item) => {
            item.fileArr = (item.file || "").split(",");
            return item;
          });
        } else {
          this.tableDataorder = [];
        }
      });
      orderbusiness({ projectId: d.id }).then((res) => {
        if (res.code == 200) {
          this.tableDatabusiness = res.data;
        } else {
          this.tableDatabusiness = [];
        }
      });
      ordermaintain(data2).then((res) => {
        if (res.code == 200) {
          this.tableDatamaintain = res.data.map((item) => {
            item.fileArr = (item.file || "").split(",");
            return item;
          });
        } else {
          this.tableData = [];
        }
      });
      orderoperation({ projectId: d.id }).then((res) => {
        if (res.code == 200) {
          this.tableDataoperation = res.data.map((item) => {
            item.fileArr = (item.file || "").split(",");
            return item;
          });
        } else {
          this.tableDataoperation = [];
        }
      });
      orderrenew({ projectId: d.id }).then((res) => {
        if (res.code == 200) {
          this.tableDatarenew = res.data;
          // this.tableDatarenew = res.data.list.map(item=>{
          //     item.p=item.itemList?item.itemList[0]taxUnitPrice:''
          //     return item
          // });
        } else {
          this.tableDatarenew = [];
        }
      });
      this.getRecordList();
      projectStatistics(d.id).then((res) => {
        if (res.code == 200) {
          this.contractableData = res.data.list;
        } else {
          this.contractableData = [];
          this.$message.error(res.data.msg);
        }
      });
      cost(d.id).then((res) => {
        if (res.code == 200) {
          this.costForm = res.data;
        } else {
          this.costForm = {};
          this.$message.error(res.data.msg);
        }
      });
      projectStatisticsMateria(d.id).then((res) => {
        if (res.code == 200) {
          this.contractableMateria = res.data;
        } else {
          this.contractableMateria = [];
          this.$message.error(res.data.msg);
        }
      });
    },
    handleCurrentChange(v) {
      this.currentPage2 = v;
      this.getData();
    },
    handleSizeChange(v) {
      this.pageSize = v;
      this.getData();
    },
  },
  mounted() {
    //获取权限
    this.permissoin = [];
    let arr = JSON.parse(localStorage.getItem("perArr"));
    let routePath = this.$route.path;
    arr.forEach((item) => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
    this.getData();
    merchantGetList({ pageSize: 0 }).then((res) => {
      if (res.code == 200) {
        this.option3 = res.data.list.filter((item) => item.type == 2);
      }
    });
    getUserList().then((res) => {
      if (res.code == 200) {
        this.option30 = res.data.filter((item) => item.type == "scb");
        let arr = res.data
          .filter((item) => item.type == "qd")
          .map((item) => item.userId)
          .join(",");
        this.option30.unshift({ name: "渠道", userId: arr });
      }
    });
  },
  components: {
    PtojectMap,
  },
};
</script>

<style lang="less" >
.AC_manage111 {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .jstitle {
    border-bottom: 1px solid #ebf0f5;
  }
  .index_main111 {
    flex: 1;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    box-sizing: border-box;

    .search {
      margin: 22px 0;
      display: flex;
      justify-content: space-btween;
      .el-input {
        width: 208px;
        margin-right: 20px;
      }
    }
    .table {
      flex: 1;
    }
    .money {
      position: fixed;
      left: 300px;
      bottom: 66px;
      display: flex;
      font-size: 12px;
      p {
        // width: 160px;
        margin-right: 30px;
        color: #606266;
        span {
          font-weight: 600;
        }
      }
      // span{
      //   color: #999;
      // }
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 460px;
      // height: 316px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        padding: 0 30px;
        padding-top: 10px;
        display: flex;
        box-sizing: border-box;
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            width: 60px;
          }
        }
        .el-form {
          .el-form-item__label {
            font-size: 12px;
            color: #222328;
          }
          .el-form-item {
            margin-bottom: 10px;
          }
          .el-input {
            width: 270px;
          }
          .el-form-item__error {
            padding-top: 0px;
          }
          // .el-form-item__content{
          //   // margin-left: 0px !important;
          //   // width: 302px;
          // }
          .two_item {
            display: flex;
            .ti_label {
              display: inline-block;
              width: 80px;
            }
            .ti_label1 {
              display: inline-block;
              width: 55px;
              margin-left: 14px;
              margin-right: 2px;
            }
            .el-input {
              width: 80px;
            }
            .little {
              font-size: 8px;
            }
          }
        }
        .mc_right {
          width: 400px;
          .mcr_title {
            margin-top: 18px;
            margin-bottom: 22px;
            i {
              font-size: 14px;
              color: #f37d00;
            }
          }
          .input_item {
            margin-bottom: 24px;

            & > span:nth-child(1) {
              color: #f56c6c;
              margin-right: 2px;
            }
            & > span:nth-child(2) {
              width: 66px;
              display: inline-block;
              margin-right: 8px;
            }
            .el-input {
              width: 270px;
            }
          }
          .jwd {
            display: flex;
            justify-content: space-between;
            font-size: 8px;
            line-height: 18px;
            margin-top: -24px;
            width: 350px;
            box-sizing: border-box;
            padding: 0 6px;
            & > span:nth-child(2) {
              color: #0090ff;
            }
          }
          .map {
            // background-color: rgb(116, 35, 35);
            width: 360px;
            height: 310px;
          }
        }
      }
      .bottom {
        height: 54px;
        display: flex;
        justify-content: center;
        margin-top: 16px;
        & > button:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
  }
  .modal6 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    align-items: center;
    .inner11111 {
      width: 1250px;
      height: 790px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      position: relative;
      .title {
        color: #2b2e36;
        line-height: 50px;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        // margin-bottom: 20px;
        // border-bottom: 1px solid #dddddd;
        i {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .el-table th {
        background-color: #fff;
        padding: 0px;
      }
      .table_item {
        margin-right: 60px;
        margin-left: 60px;
        font-size: 12px;
        .table_item_title {
          font-size: 14px;
          color: #000;
          line-height: 30px;
        }
        .jstitle {
          line-height: 30px;
          span {
            display: inline-block;
            margin-right: 10px;
          }
          & > span:nth-child(1) {
            width: 150px;
          }
          & > span:nth-child(2) {
            width: 60px;
          }
          & > span:nth-child(3) {
            width: 60px;
          }
          & > span:nth-child(4) {
            width: 60px;
          }
        }
      }
      .el-progress {
        width: 600px;
      }
      .clitem {
        .sel1 {
          margin-right: 20px;

          .spa {
            display: inline-block;
            color: #999;
            width: auto;
          }
        }
      }
      .map {
        width: 300px;
        height: 200px;
      }
      .ii {
        i {
          cursor: pointer;
          font-size: 14px;
          line-height: 26px;
          margin-left: 20px;
        }
      }
      .el-tabs--border-card {
        min-height: 730px;
      }
      .sel {
        display: flex;
        margin-bottom: 14px;
        line-height: 26px;
        .el-input__inner {
          width: 200px;
        }
        .el-input {
          width: 200px;
        }
        .el-select .el-input {
          width: 200px;
        }
      }
      .spa {
        display: inline-block;
        width: 80px;
        color: #999;
      }
    }
  }
}
</style>